.flip-tool-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  .text-box {
    border-radius: 10px;
    background: linear-gradient(91deg, #E0DDFF -3.82%, #FFF 22.14%, #E4E5FF 48.09%, #FFF 74.05%, #D5D7FF 100%);

    .text-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .content-text {
        text-align: center;
        color: #fff;
        text-shadow: 0 2px 10px #B1AFFF;
        font-family: SVN-Gotham, serif;
        background: linear-gradient(90deg, #5858CD -93.62%, #6B6BFF 3.05%, #BBA3FF 87.8%, #E2D0FF 160.64%);
        background-clip: text;
        -webkit-text-stroke: 2px #fff;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}