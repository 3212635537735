@font-face {
    font-family: SVN-Gotham;
    src: url("../../assets/fonts/SVN-gotham/SVN-Gotham Bold.otf");
}


.main-bg {
  background: url("../../assets/images/background.png") no-repeat bottom center;
  background-size: cover;
}

.bg-gradient-1 {
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(206, 188, 255, 0.00) 0%, #A6AFFF 50.46%, rgba(209, 188, 255, 0.00) 99.92%);
}

.bg-gradient-2 {
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(206, 188, 255, 0.00) 0%, #C4CAFF 50.46%, rgba(209, 188, 255, 0.00) 99.92%);
}

.bg-gradient-3 {
  border-radius: 20px;
  border: 1px solid #ebc9ff;
  background: radial-gradient(60.87% 60.87% at 50% 50%, #DFBCFF 0%, #7581F4 100%);
  box-shadow: 0 4px 10px 0 #B7B0FF;

  &:active {
    transform: translateY(3px);
    box-shadow: 0 3px 10px 0 #B7B0FF;
  }

  &:disabled{
    background: #dfdfdf;
    box-shadow: 0 1px 10px 0 #B7B0FF;
  }
}

.bg-gradient-4 {
  background: var(--GRAD-NEW-12, linear-gradient(147deg, #5858CD -74.17%, #6B6BFF 1.44%, #BBA3FF 67.73%, #E2D0FF 124.7%));
}

.text-gradient-1 {
  background: linear-gradient(180deg, #A55FD7 0%, #5953C5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal{
  &-content{
    background: url("../../assets/images/background.png") no-repeat bottom center;
    background-size: cover;

    .prize-box{
      border-radius: 10px;

      &-body{
        background: white;
      }
    }
  }
}